var sw = sw || {};

// Toggle the mobile menu on/off canvas
//-----------------------------------------------------------------
sw.toggleMenu = function() {

    var $toggleButton = $('.js_toggleMenu'),
        $innerWrap = $('.menu__inner'),
        $drawerWraper = $('.menu');


    if (!$toggleButton.length) {
        return;
    }


    //START Locking body scroll logic
    var $body = $('body'),
        scrollDisabled = false,
        scrollTop;

    function scrollDisable() {
        if (scrollDisabled) {return;}
        scrollTop = $(window).scrollTop();
        $body.addClass('locked').css({ top: -1 * scrollTop });
        scrollDisabled = true;
    }

    function scrollEnable() {
        if (!scrollDisabled) {return;}
        $body.removeClass('locked');
        $(window).scrollTop(scrollTop);
        scrollDisabled = false;
    }
    //END Locking body scroll logic



    $toggleButton.on('click', function(e) {
        e.preventDefault();

        if ( $drawerWraper.hasClass('is_open') ) {
            $drawerWraper.removeClass('is_open');
            $(this).removeClass('is_open');
            scrollEnable();
        }
        else {
            $drawerWraper.addClass('is_open');
            $(this).addClass('is_open');
            scrollDisable();
        }


        //calculate inner height depending of viewport
        var _vph = $(window).height();
        var _vpw = $(window).width();

        if (_vpw < 1024) {
            $innerWrap.css('min-height', _vph - 100);
        }

    });

};



// Toggle submenu on pressed link
//-----------------------------------------------------------------
sw.toggleMobileSubmenu = function() {

    var $toggleButton = $('.menu__link--toggle');

    if (!$toggleButton.length) {
        return;
    }

    $toggleButton.on('click', function(e) {
        e.preventDefault();
        $(this).toggleClass('is_open');
        $(this).next('.submenu').toggleClass('is_open');
   });

};





$(document).ready(function (){
    sw.toggleMenu();
    sw.toggleMobileSubmenu();
});



//every function here inits after resizing window
$(window).bind('resizeEnd', function() {
        //code to do after window is resized

        var _vpw = $(window).width(),
            _vph = $(window).height(),
            $innerWrap = $('.menu__inner'),
            $toggleButton = $('.menu__link--toggle');

        if (_vpw < 1024) {
            $innerWrap.css('min-height', _vph - 60);
        }
        else {
            $('.menu__item--has_children .submenu').removeClass('is_open');
            $toggleButton.removeClass('is_open');
        }

});