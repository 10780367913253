var sw = sw || {};


// Showing/hiding header on scroll
//-----------------------------------------------------------------
sw.toggleHeader = function() {

    var $header = $('.header'),
        headerHeight = $header.outerHeight(),
        lastScrollTop = 0,
        offset = 5,
        didScroll;

    if (!$header.length) {
        return;
    }

    $(window).scroll(function(event){
        didScroll = true;
    });

    setInterval(function() {
        if (didScroll) {
            hasScrolled();
            didScroll = false;
        }
    }, 250);

    function hasScrolled() {
        var st = $(this).scrollTop();

        if (Math.abs(lastScrollTop - st) <= offset) {
            return;
        }

        if (st > lastScrollTop && st > headerHeight){
            // Scroll down
            $header.removeClass('nav_down').addClass('nav_up');
        }
        else if (st + $(window).height() < $(document).height()) {
            // Scroll up
            $header.removeClass('nav_up').addClass('nav_down');
        }
        
        if ($(window).scrollTop() <= 120) {
            $header.removeClass('nav_down nav_up');
        }

        lastScrollTop = st;
    }

};



$(document).ready(function (){
    sw.toggleHeader();
});