var sw = sw || {};

// Slider in first fold
//-----------------------------------------------------------------
sw.heroSlider = function() {

    var $slider = $('.js_heroSlider').removeClass('visuallyhidden');

    if (!$slider.length) {
        return;
    }

    $slider.slick({
        mobileFirst: true,
        autoplay: true,
        autoplaySpeed: 5000,
        fade: true,
        arrows: true,
        dots: true,
        prevArrow: '<div class="slick-prev"><i class="icon-arrow-big-left"></i></div>',
        nextArrow: '<div class="slick-next"><i class="icon-arrow-big-right"></i></div>'
        // responsive: [{
        //     breakpoint: 767,
        //     settings: {
        //         arrows: true,
        //         dots: true,
        //     }
        // }]
    });

};




// Slider for best offers
//-----------------------------------------------------------------
sw.topoffersSlider = function() {

    var $slider = $('.js_topoffersSlider').removeClass('visuallyhidden');

    if (!$slider.length) {
        return;
    }

    $slider.slick({
        mobileFirst: true,
        // autoplay: true,
        // autoplaySpeed: 5000,
        // fade: true,
        // arrows: true,
        // dots: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        prevArrow: '<div class="slick-prev"><i class="icon-arrow-big-left"></i></div>',
        nextArrow: '<div class="slick-next"><i class="icon-arrow-big-right"></i></div>',
        responsive: [
            {
              breakpoint: 1024,
              settings: {
                slidesToShow: 3
              }
            },
            {
              breakpoint: 480,
              settings: {
                slidesToShow: 2
              }
            }
        ]
    });

};




$(document).ready(function (){
    sw.heroSlider();
    sw.topoffersSlider();
});