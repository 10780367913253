var sw = sw || {};

// Init Selectric
//-----------------------------------------------------------------
sw.selectric = function() {
    var $trigger = $('.form__select');

    if (!$trigger.length) {
        return;
    }

    $trigger.selectric({
        disableOnMobile: true,
        nativeOnMobile: true,
        arrowButtonMarkup: '<b class="button"></b>',

        multiple: {
          separator: ', ',       // Type: String.             Description: Items separator.
          keepMenuOpen: true,    // Type: Boolean.            Description: Close after an item is selected.
          maxLabelEntries: false // Type: Boolean or Integer. Description: Max selected items do show.
        }
    });
}



// Enable "sticky" sidebar
//-----------------------------------------------------------------
sw.stickyFill = function() {
    var stickyElements = document.getElementsByClassName('sticky');

    for (var i = stickyElements.length - 1; i >= 0; i--) {
        Stickyfill.add(stickyElements[i]);
    }
};




// Enable responsive youtube video
//-----------------------------------------------------------------
sw.responsiveVideo = function() {
    var $parentEl = $('.wysiwyg');
    var $videoEl = $('iframe');

    if (!$videoEl.length) {
        return;
    }

    $parentEl.find('iframe[src*="youtube"]').wrap('<div class="video-container"></div>');
};



// Show extra field in forms
//-----------------------------------------------------------------
sw.hiddenFields = function() {
    // var $parentEl = $('.wysiwyg');
    // var $videoEl = $('iframe');

    // if (!$videoEl.length) {
    //     return;
    // }

    $('.js_hiddenFields').on('click' , function(){

        console.log( $(this).is(':checked') );

        if ( $(this).is(':checked') ) {
            $(this).parent().parent().next('.form__fieldset--hidden').removeClass('form__fieldset--hidden');
        }
        else {
            $(this).parent().parent().next('.form__fieldset').addClass('form__fieldset--hidden');
        }
    });

};


// Show extra form for adding vehicles
//-----------------------------------------------------------------
sw.addVehicleForm = function() {
    // var $parentEl = $('.wysiwyg');
    // var $videoEl = $('iframe');

    // if (!$videoEl.length) {
    //     return;
    // }

    $('.js_addVehicleForm').on('click', function() {

        console.log( $(this).is(':checked') );

        if ( $(this).is(':checked') ) {
            $(this).parent().parent().parent().parent().siblings('.form').removeClass('hidden');
            $(this).parent().siblings('.description').addClass('is_open');
            $('#user-vehicles').addClass('hidden');
        }
        else {
            $(this).parent().parent().parent().parent().siblings('.form').addClass('hidden');
            $(this).parent().siblings('.description').removeClass('is_open');
            $('#user-vehicles').removeClass('hidden');
        }
    });

};



// Date picker on reservation
//-----------------------------------------------------------------
sw.pikaday = function() {
    var $inputEl = $('.js_pikaday');

    if (!$inputEl.length) {
        return;
    }

    $inputEl.pikaday({
        minDate: new Date(),
        firstDay: 1,
        format: 'DD.MM.YYYY.',
        yearRange: [2018,2019],
        i18n: {
            previousMonth : 'Prethodni mjesec',
            nextMonth     : 'Sljedeći mjesec',
            months        : ['Siječanj','Veljača','Ožujak','Travanj','Svibanj','Lipanj','Srpanj','Kolovoz','Rujan','Listopad','Studeni','Prosinac'],
            weekdays      : ['Nedjelja','Ponedjeljak','Utorak','Srijeda','Četvrtak','Petak','Subota'],
            weekdaysShort : ['Ned','Pon','Uto','Sri','Čet','Pet','Sub']
        }
    });
};


// Get vehicle models
//-----------------------------------------------------------------
sw.getCarModels = function() {

    var $element = $('.js_getVehicleModel');

    if (!$element.length) {
        return;
    }

    $element.on('change', function () {
        $currentElement = $(this);
        $modelID = $currentElement.val();

        $.post('/ajax/get-vehicle-models', { id: $modelID})
            .done(function(data){
                $currentElement.parents('form').find('.js_vehicleModels').html(data);

                if ($element.val() == "") {
                    $('.js_vehicleModels').prop('disabled', true).selectric('refresh');
                }
                else {
                    $('.form__select').prop('disabled', false).selectric('refresh');
                }
            });
    });
};



// Auto-remove notifications
//-----------------------------------------------------------------
sw.removeNotification = function() {
    var $el = $('.notification');

    if (!$el.length) {
        return;
    }

    setTimeout(function() {
        if ($el.length > 0) {
            $el.fadeOut();
            $el.remove();
        }
    }, 5000);
};



// Fancy input fileds in forms
//-----------------------------------------------------------------
// sw.fancyInputs = function() {

//     $('.form__textfield').val('');

//     $('.form__textfield').on('focusout', function() {
//         if ($(this).val() != ''){
//             $(this).addClass('has-content');
//         }
//         else {
//             $(this).removeClass('has-content');
//         }
//     });
// };







$(document).ready(function (){
    sw.selectric();
    sw.stickyFill();
    sw.responsiveVideo();
    sw.pikaday();
    sw.hiddenFields();
    sw.getCarModels();
    sw.removeNotification();
    sw.addVehicleForm();
});