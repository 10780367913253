var sw = sw || {};

sw.click_touchstart = (function(){
    return Modernizr.touchEvents ? 'touchstart' : 'click';
})();


// When ready do stuff
//----------------------------------------------------------
$(document).ready(function(){

});


// Function for resizing with timeout
//----------------------------------------------------------
$(window).resize(function() {
    if(this.resizeTo) clearTimeout(this.resizeTo);
    this.resizeTo = setTimeout(function() {
        $(this).trigger('resizeEnd');
    }, 200);
});

//every function here inits after resizing window
$(window).bind('resizeEnd', function() {
        //code to do after window is resized

        //check if need to load articles on resize (doesn't load on mobile/tablet)
        // sw.loadArticles();
});