var sw = sw || {};


// Countdown in first fold
//-----------------------------------------------------------------
sw.magnificPopupIframe = function() {

    $('.js_popupIframe').magnificPopup({
        disableOn: 700,
        type: 'iframe',
        mainClass: 'mfp-fade',
        removalDelay: 160,
        preloader: false,
        fixedContentPos: false
    });

};




// Inline pop-ups
//-----------------------------------------------------------------
sw.magnificPopupInline = function() {

    $('.js_popupInline').magnificPopup({
        type: 'inline',
        fixedContentPos: false,
        fixedBgPos: true,
        overflowY: 'auto',
        closeBtnInside: true,
        preloader: false,
        midClick: true,
        removalDelay: 300,
        mainClass: 'my-mfp-zoom-in'
    });

};


// Ajax pop-ups
//-----------------------------------------------------------------
sw.magnificPopupAjax = function() {

    $('.js_popupAjax').magnificPopup({
      type: 'ajax',
      // alignTop: true,
      overflowY: 'scroll' // as we know that popup content is tall we set scroll overflow by default to avoid jump
    });

};



// Images in popup
//-----------------------------------------------------------------
sw.magnificPopupImage = function() {

    $('.js_popupImage').magnificPopup({
        type: 'image'
    });

};



$(document).ready(function (){
    sw.magnificPopupIframe();
    sw.magnificPopupInline();
    sw.magnificPopupAjax();
    sw.magnificPopupImage();
});