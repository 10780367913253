var sw = sw || {};


// Form validation for login
//----------------------------------------------------------
sw.formLogin = function() {
    var $form = $('#form-login');

    if (!$form.length) {
        return;
    }

    $form.validate({
        rules: {
            email: {
                required: true,
                minlength: 3,
                email: true
            },
            password: {
                required: true,
                minlength: 6
            }
        },
        highlight: function(element) {
            $(element).parent().addClass('form__fieldset--error');
        },
        unhighlight: function(element) {
            $(element).parent().removeClass('form__fieldset--error');
        }
    });
};





// Form validation for register
//----------------------------------------------------------
sw.formRegister = function() {
    var $form = $('#form-register');

    if (!$form.length) {
        return;
    }

    $form.validate({
        rules: {
            email: {
                required: true,
                minlength: 3,
                email: true
            },
            password: {
                required: true,
                minlength: 6
            },
            name: {
                required: true,
                minlength: 2
            }
        },
        highlight: function(element) {
            $(element).parent().addClass('form__fieldset--error');
        },
        unhighlight: function(element) {
            $(element).parent().removeClass('form__fieldset--error');
        }
    });
};





// Form validation for register
//----------------------------------------------------------
sw.formRegister2 = function() {
    var $form = $('#form-register2');

    if (!$form.length) {
        return;
    }

    $form.validate({
        rules: {
            email: {
                required: true,
                minlength: 3,
                email: true
            },
            password: {
                required: true,
                minlength: 6
            },
            name: {
                required: true,
                minlength: 2
            }
        },
        highlight: function(element) {
            $(element).parent().addClass('form__fieldset--error');
        },
        unhighlight: function(element) {
            $(element).parent().removeClass('form__fieldset--error');
        }
    });
};





// Form validation for newsletter
//----------------------------------------------------------
sw.formNewsletter = function() {
    var $newsletterForm = $('#form-newsletter');

    if (!$newsletterForm.length) {
        return;
    }

    $newsletterForm.validate({
        rules: {
            newsletter_mail: {
                required: true,
                minlength: 3,
                email: true
            }
        }
    });
};





// Form validation for contact
//----------------------------------------------------------
sw.formContact = function() {
    var $form = $('#form-contact');

    if (!$form.length) {
        return;
    }

    $form.validate({
        rules: {
            ime: {
                required: true,
                minlength: 2,
            },
            prezime: {
                required: true,
                minlength: 2,
            },
            email: {
                required: true,
                minlength: 3,
                email: true
            },
            telefon: {
                required: true,
                minlength: 6
            },
            poruka: {
                required: true,
                minlength: 10
            }
        },
        highlight: function(element) {
            $(element).parent().addClass('form__fieldset--error');
        },
        unhighlight: function(element) {
            $(element).parent().removeClass('form__fieldset--error');
        }
    });
};





// Form validation for loyalty
//----------------------------------------------------------
sw.formLoyalty = function() {
    var $form = $('#form-loyalty');

    if (!$form.length) {
        return;
    }

    $form.validate({
        rules: {
            ime: {
                required: true,
                minlength: 2,
            },
            prezime: {
                required: true,
                minlength: 2,
            },
            grad: {
                required: true,
                minlength: 2,
            },
            adresa: {
                required: true,
                minlength: 2,
            },
            email: {
                required: true,
                minlength: 3,
                email: true
            },
            telefon: {
                required: true,
                minlength: 6
            }
        },
        highlight: function(element) {
            $(element).parent().addClass('form__fieldset--error');
        },
        unhighlight: function(element) {
            $(element).parent().removeClass('form__fieldset--error');
        }
    });
};





// Form validation for appointment
//----------------------------------------------------------
sw.formAppointment = function() {
    var $form = $('#form-appointment');

    if (!$form.length) {
        return;
    }

    $form.validate({
        rules: {
            ime: {
                required: true,
                minlength: 2,
            },
            prezime: {
                required: true,
                minlength: 2,
            },
            email: {
                required: true,
                minlength: 3,
                email: true
            },
            telefon: {
                required: true,
                minlength: 6
            },
            datum: {
                required: true
            }
        },
        highlight: function(element) {
            $(element).parent().addClass('form__fieldset--error');
        },
        unhighlight: function(element) {
            $(element).parent().removeClass('form__fieldset--error');
        }
    });
};





// Form validation for online offer
//----------------------------------------------------------
sw.formOnlineOffer = function() {
    var $form = $('#form-online-offer');

    if (!$form.length) {
        return;
    }

    $form.validate({
        rules: {
            marka_vozila: {
                required: true
            },
            tip_motora: {
                required: true,
                minlength: 3
            },
            godina_proizvodnje: {
                required: true
            },
            snaga_motora: {
                required: true,
                minlength: 2,
                number: true
            },
            ime: {
                required: true,
                minlength: 2,
            },
            prezime: {
                required: true,
                minlength: 2,
            },
            email: {
                required: true,
                minlength: 3,
                email: true
            },
            telefon: {
                required: true,
                minlength: 6
            },
            poruka: {
                required: true
            }
        },
        highlight: function(element) {
            $(element).parent().addClass('form__fieldset--error');
        },
        unhighlight: function(element) {
            $(element).parent().removeClass('form__fieldset--error');
        }
    });
};





// Form validation for damage evaluation
//----------------------------------------------------------
sw.formDamageEvaluation = function() {
    var $form = $('#form-damage-evaluation');

    if (!$form.length) {
        return;
    }

    $form.validate({
        rules: {
            ime: {
                required: true,
                minlength: 2,
            },
            prezime: {
                required: true,
                minlength: 2,
            },
            email: {
                required: true,
                minlength: 3,
                email: true
            },
            telefon: {
                required: true,
                minlength: 6
            },
            opis_stete: {
                required: true
            }
        },
        highlight: function(element) {
            $(element).parent().addClass('form__fieldset--error');
        },
        unhighlight: function(element) {
            $(element).parent().removeClass('form__fieldset--error');
        }
    });
};





// Form validation for adding new vehicle
//----------------------------------------------------------
sw.formAddVehicle = function() {
    var $form = $('#form-add-vehicle');

    if (!$form.length) {
        return;
    }

    $form.validate({
        rules: {
            marka_vozila: {
                required: true
            },
            tip_motora: {
                required: true,
                minlength: 3
            },
            godina_proizvodnje: {
                required: true
            },
            snaga_motora: {
                required: true,
                minlength: 2,
                number: true
            },
        },
        highlight: function(element) {
            $(element).parent().addClass('form__fieldset--error');
        },
        unhighlight: function(element) {
            $(element).parent().removeClass('form__fieldset--error');
        }
    });
};





// Form validation for user data form (private profile)
//----------------------------------------------------------
sw.formUserDataPrivate = function() {
    var $form = $('#form-user-data-private');

    if (!$form.length) {
        return;
    }

    $form.validate({
        rules: {
            ime: {
                required: true,
                minlength: 2,
            },
            prezime: {
                required: true,
                minlength: 2,
            },
            email: {
                required: true,
                minlength: 3,
                email: true
            }
        },
        highlight: function(element) {
            $(element).parent().addClass('form__fieldset--error');
        },
        unhighlight: function(element) {
            $(element).parent().removeClass('form__fieldset--error');
        }
    });
};





// Form validation for user data form (company profile)
//----------------------------------------------------------
sw.formUserDataCompany = function() {
    var $form = $('#form-user-data-company');

    if (!$form.length) {
        return;
    }

    $form.validate({
        rules: {
            ime_tvrtke: {
                required: true,
                minlength: 2,
            },
            oib_tvrtke: {
                required: true,
                minlength: 11,
                number: true
            },
            email: {
                required: true,
                minlength: 3,
                email: true
            }
        },
        highlight: function(element) {
            $(element).parent().addClass('form__fieldset--error');
        },
        unhighlight: function(element) {
            $(element).parent().removeClass('form__fieldset--error');
        }
    });
};






// Form validation for forms on homepage
//----------------------------------------------------------
sw.formInquiry = function() {
    var $form = $('.form-homepage-inquiry');

    if (!$form.length) {
        return;
    }

    $form.validate({
        rules: {
            ime: {
                required: true,
                minlength: 2,
            },
            prezime: {
                required: true,
                minlength: 2,
            },
            email: {
                required: true,
                minlength: 3,
                email: true
            },
            telefon: {
                required: true,
                minlength: 6
            }
        },
        highlight: function(element) {
            $(element).parent().addClass('form__fieldset--error');
        },
        unhighlight: function(element) {
            $(element).parent().removeClass('form__fieldset--error');
        }
    });
};





$(document).ready(function (){
    sw.formLogin();
    sw.formRegister();
    sw.formRegister2();
    sw.formNewsletter();
    sw.formContact();
    sw.formLoyalty();
    sw.formAppointment();
    sw.formOnlineOffer();
    sw.formDamageEvaluation();
    sw.formAddVehicle();
    sw.formUserDataPrivate();
    sw.formUserDataCompany();
    sw.formInquiry();
});